define("ember-basic-dropdown/templates/components/basic-dropdown/trigger", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "p5wFE5iz",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,null]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "ember-basic-dropdown/templates/components/basic-dropdown/trigger.hbs"
    }
  });

  _exports.default = _default;
});