define('ember-data-sails/serializers/sails', ['exports', 'ember-data', 'ember-data-sails/mixins/with-logger', 'ember-data-sails/adapters/sails-socket'], function (exports, _emberData, _withLogger, _sailsSocket) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var $ = Ember.$,
      computed = Ember.computed,
      get = Ember.get;
  var pluralize = Ember.String.pluralize;


  function blueprintsWrapMethod(method) {
    return function () {
      return (get(this, 'useSailsEmberBlueprints') ? this._super : method).apply(this, arguments);
    };
  }

  /**
   * @class SailsSerializer
   * @extends DS.RESTSerializer
   */
  var SailsSerializer = _emberData.default.RESTSerializer.extend(_withLogger.default, {
    /**
     * The config of the addon will be set here by the initializer
     * @since 0.0.17
     * @property config
     * @type Object
     */
    config: {},

    /**
     * Whether to use `sails-generate-ember-blueprints` or not
     * @since 0.0.15
     * @property useSailsEmberBlueprints
     * @type Boolean
     */
    useSailsEmberBlueprints: computed.readOnly('config.useSailsEmberBlueprints'),

    /**
     * @since 0.0.11
     * @method extractArray
     * @inheritDoc
     */
    normalizeArrayResponse: blueprintsWrapMethod(function (store, primaryType, payload) {
      var newPayload = {};
      newPayload[pluralize(primaryType.modelName)] = payload;
      return this._super.apply(this, arguments);
    }),

    /**
     * @since 0.0.11
     * @method extractSingle
     * @inheritDoc
     */
    normalizeSingleResponse: blueprintsWrapMethod(function (store, primaryType, payload, recordId) {
      if (payload === null) {
        return this._super.apply(this, arguments);
      }
      var newPayload = {};
      newPayload[pluralize(primaryType.modelName)] = [payload];
      return this._super.apply(this, arguments);
    }),

    /**
     * @since 0.0.11
     * @method serializeIntoHash
     * @inheritDoc
     */
    serializeIntoHash: blueprintsWrapMethod(function (data, type, record, options) {
      if (Object.keys(data).length > 0) {
        this.error('trying to serialize multiple records in one hash for type ' + type.modelName, data);
        throw new Error('Sails does not accept putting multiple records in one hash');
      }
      var json = this.serialize(record, options);
      $.extend(data, json);
    }),

    /**
     * @since 0.0.11
     * @method normalize
     * @inheritDoc
     */
    normalize: blueprintsWrapMethod(function (type, hash, prop) {
      var normalized = this._super.apply(this, arguments);
      return this._extractEmbeddedRecords(this, this.store, type, normalized);
    }),

    /**
     * @since 0.0.15
     * @method extract
     * @inheritDoc
     */
    normalizeResponse: function normalizeResponse(store, primaryModelClass /*, payload, id, requestType*/) {
      // this is the only place we have access to the store, so that we can get the adapter and check
      // if it is an instance of sails socket adapter, and so register for events if necessary on that
      // model. We keep a cache here to avoid too many calls
      if (!this._modelsUsingSailsSocketAdapter) {
        this._modelsUsingSailsSocketAdapter = Object.create(null);
      }
      var modelName = primaryModelClass.modelName;
      if (this._modelsUsingSailsSocketAdapter[modelName] === undefined) {
        var adapter = store.adapterFor(modelName);
        this._modelsUsingSailsSocketAdapter[modelName] = adapter instanceof _sailsSocket.default;
        adapter._listenToSocket(modelName);
      }
      return this._super.apply(this, arguments);
    },

    /**
     * Extract the embedded records and create them
     *
     * @since 0.0.11
     * @method _extractEmbeddedRecords
     * @param {subclass of DS.Model} type
     * @param {Object} hash
     * @returns {Object}
     * @private
     */
    _extractEmbeddedRecords: function _extractEmbeddedRecords(serializer, store, type, hash) {
      var _this = this;

      type.eachRelationship(function (key, rel) {
        var modelName = rel.type.modelName;
        var data = hash[key];
        var serializer = store.serializerFor(modelName);
        if (data) {
          if (rel.kind === 'belongsTo') {
            if (Ember.typeOf(hash[key]) === 'object') {
              _this.debug('found 1 embedded ' + modelName + ' record:', hash[key]);
              delete hash[key];
              store.push(rel.type, serializer.normalize(rel.type, data, null));
              hash[key] = data.id;
            }
          } else if (rel.kind === 'hasMany') {
            hash[key] = data.map(function (item) {
              if (Ember.typeOf(item) === 'object') {
                this.debug('found 1 embedded ' + modelName + ' record:', item);
                store.push(rel.type, serializer.normalize(rel.type, item, null));
                return item.id;
              }
              return item;
            });
          } else {
            _this.warn('unknown relationship kind ' + rel.kind + ':', rel);
            throw new Error('Unknown relationship kind ' + rel.kind);
          }
        }
      });
      return hash;
    }
  });

  exports.default = SailsSerializer;
});