define("pagination-pager/components/page-item", ["exports", "ember"], function (_exports, _ember) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = _ember.default.computed;

  var _default = _ember.default.Component.extend({
    tagName: 'li',
    classNameBindings: ['isActive:active', 'disabled'],
    seperator: '…',
    selected: null,
    url: computed('urlTemplate', 'page', function () {
      var urlTemplate = this.get('urlTemplate');
      var current = this.get('page');
      urlTemplate = urlTemplate.replace('{current}', current);
      return urlTemplate;
    }),
    isActive: computed('page', 'selected', function () {
      try {
        return this.get('page') === Number(this.get('selected'));
      } catch (e) {
        return false;
      }
    }),
    isDots: computed('page', function () {
      var seperator = this.get('seperator');
      var page = this.get('page');
      return page === seperator;
    }),
    actions: {
      select: function () {
        var last = this.get('selected');
        var page = this.get('page');

        if (page !== last) {
          this.sendAction('pageSet', page, last);
        }
      }
    }
  });

  _exports.default = _default;
});