define("liquid-fire/components/liquid-measured", ["exports", "liquid-fire/mutation-observer", "liquid-fire/templates/components/liquid-measured"], function (_exports, _mutationObserver, _liquidMeasured) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.measure = measure;
  _exports.default = void 0;
  var WINDOW_RESIZE_THROTTLE_DURATION = 100;

  var _default = Ember.Component.extend({
    layout: _liquidMeasured.default,
    init: function init() {
      this._super.apply(this, arguments);

      this._destroyOnUnload = this._destroyOnUnload.bind(this);
    },
    didInsertElement: function didInsertElement() {
      var self = this; // This prevents margin collapse

      this.element.style.overflow = 'auto';
      this.didMutate();
      this.observer = new _mutationObserver.default(function (mutations) {
        self.didMutate(mutations);
      });
      this.observer.observe(this.get('element'), {
        attributes: true,
        subtree: true,
        childList: true,
        characterData: true
      });
      this.windowResizeHandler = this.windowDidResize.bind(this);
      window.addEventListener('resize', this.windowResizeHandler);
      var elt = Ember.$(this.element);
      elt.bind('webkitTransitionEnd', function () {
        self.didMutate();
      }); // Chrome Memory Leak: https://bugs.webkit.org/show_bug.cgi?id=93661

      window.addEventListener('unload', this._destroyOnUnload);
    },
    willDestroyElement: function willDestroyElement() {
      if (this.observer) {
        this.observer.disconnect();
      }

      window.removeEventListener('resize', this.windowResizeHandler);
      window.removeEventListener('unload', this._destroyOnUnload);
    },
    transitionMap: Ember.inject.service('liquid-fire-transitions'),
    didMutate: function didMutate() {
      // by incrementing the running transitions counter here we prevent
      // tests from falling through the gap between the time they
      // triggered mutation the time we may actually animate in
      // response.
      var tmap = this.get('transitionMap');
      tmap.incrementRunningTransitions();
      Ember.run.next(this, function () {
        this._didMutate();

        tmap.decrementRunningTransitions();
      });
    },
    windowDidResize: function windowDidResize() {
      Ember.run.throttle(this, this.didMutate, WINDOW_RESIZE_THROTTLE_DURATION);
    },
    _didMutate: function _didMutate() {
      if (!this.element) {
        return;
      }

      var elt = Ember.$(this.element);
      this.didMeasure(measure(elt));
    },
    _destroyOnUnload: function _destroyOnUnload() {
      this.willDestroyElement();
    }
  });

  _exports.default = _default;

  function measure($elt) {
    var boundingRect = $elt[0].getBoundingClientRect(); // Calculate the scaling.
    // NOTE: We only handle the simple zoom case.

    var claimedWidth = $elt[0].offsetWidth; // Round the width because offsetWidth is rounded

    var actualWidth = Math.round(boundingRect.width);
    var scale = actualWidth > 0 ? claimedWidth / actualWidth : 0;
    return {
      width: boundingRect.width * scale,
      height: boundingRect.height * scale
    };
  }
});