window.EmberENV = (function(EmberENV, extra) {
  for (var key in extra) {
    EmberENV[key] = extra[key];
  }

  return EmberENV;
})(window.EmberENV || {}, {"LOG_STACKTRACE_ON_DEPRECATION":false,"I18N_COMPILE_WITHOUT_HANDLEBARS":true,"FEATURES":{"I18N_TRANSLATE_HELPER_SPAN":false,"CC_SHOW_VERSION":false,"CC_EASYBILL":true,"CC_SEVDESK":true},"_APPLICATION_TEMPLATE_WRAPPER":false,"_DEFAULT_ASYNC_OBSERVERS":true,"_JQUERY_INTEGRATION":true,"_TEMPLATE_ONLY_GLIMMER_COMPONENTS":true});

var runningTests = false;


