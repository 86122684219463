define("ember-gridstack/templates/components/grid-stack", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vSaZq00S",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,[[32,0]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "ember-gridstack/templates/components/grid-stack.hbs"
    }
  });

  _exports.default = _default;
});