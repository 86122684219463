define('ember-data-sails/initializers/ember-data-sails', ['exports', 'ember-data', 'ember-data-sails/mixins/with-logger', 'ember-data-sails/mixins/store', 'ember-data-sails/services/sails-socket'], function (exports, _emberData, _withLogger, _store, _sailsSocket) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  var get = Ember.get;


  _emberData.default.Store.reopen(_store.default);

  function initialize(application) {
    var _console;

    var methods = {};
    var shouldLog = false;
    (_console = console).log.apply(_console, arguments);
    var minLevel = get(application, 'SAILS_LOG_LEVEL');
    _withLogger.LEVELS.forEach(function (level) {
      if (level === minLevel) {
        shouldLog = true;
      }
      if (!shouldLog) {
        methods[level] = function () {};
      }
    });
    _withLogger.default.reopen(methods);

    application.register('service:sails-socket', _sailsSocket.default);
    application.register('config:ember-data-sails', get(application, 'emberDataSails') || {}, { instantiate: false });

    // setup injections
    application.inject('adapter', 'sailsSocket', 'service:sails-socket');
    application.inject('serializer', 'config', 'config:ember-data-sails');
    application.inject('route', 'sailsSocket', 'service:sails-socket');
    application.inject('controller', 'sailsSocket', 'service:sails-socket');
  }

  exports.default = {
    name: 'ember-data-sails',
    before: 'ember-data',

    initialize: initialize
  };
});